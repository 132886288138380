import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Simplified from "../assets/TaskCards/tc-icon-streamlined-compliance.svg";
import Manuals from "../assets/TaskCards/tc-icon-manuals.svg";
import Signoff from "../assets/TaskCards/tc-signoffs.svg";
import SignoffMin from "../assets/TaskCards/tc-icon-signoffs.svg";
import SimplifiedTasks from "../assets/TaskCards/tc-simplified-task-management.svg";
import DetailedSteps from "../assets/TaskCards/tc-accessible-data.svg";
import DigitalTC from "../assets/TaskCards/digital-task-cards.svg";
import EngineerCAMO from "../assets/CAMO/engineer-camo.svg";
import Tick from "../assets/icon-tick.svg";
import CAMP from "../assets/camp-logo@2x.png";
import AMOS from "../assets/amos_logo.png";
import SPEC from "../assets/spec_logo.png";
import Started from "../components/Layout/started";
import TechCard from "../components/TechLog/techCard";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Seamless from "../components/Layout/seamlessIntegration";
import pageStyles from "./page.module.less";
import styles from "./taskcards.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";

const SimplifiedCard = {
  img: SimplifiedTasks,
  title: "Simplified Task Management",
  text:
    "Task Cards ensures your engineering team are working on the optimum tasks at any given time. Full visibility of records ensures nothing is missed and aircraft are released on time."
};

const StepsCard = {
  img: DetailedSteps,
  title: "Easily Accessible Data",
  text:
    "Task Cards makes all the required information readily available allowing engineers to focus on the tasks at hand whilst ensuring they are working to correct procedures."
};

const StreamlinedCard = {
  img: Signoff,
  title: "Streamlined Sign Off Process",
  text:
    "Task Cards makes inspector sign off simple while still bringing added checks and validation to reduce the risks of any non-compliance."
};

const cardsArray = [SimplifiedCard, StepsCard, StreamlinedCard];

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 15px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 0.02px;
  color: rgba(36, 45, 65, 0.7);
  padding: 20px 0;
`;


export default props => {
  const { mainImage, digitalSolutionsImg, benefitsImg } = props.data;
  const cards = cardsArray.map((card, key) => (
    <Col md={4} className={`${styles.techCardCol} ${pageStyles.coliPad}`}>
      <ScrollAnimation
        animateOnce
        animateIn={
          key === 0 ? "fadeInLeft" : key === 1 ? "fadeInUp" : "fadeInRight"
        }
        offset={0}
      >
        <TechCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
        />
      </ScrollAnimation>
    </Col>
  ));

  const seamlessCards = [
    {
      img: AMOS,
      title: "AMOS"
    },
    {
      title: "And more"
    },
    {
      img: SPEC,
      title: "SPEC 2000"
    },
    {
      img: CAMP,
      title: "CAMP"
    }
  ];

  return (
    <Layout>
      <SEO
        title="TaskCards"
        description="Streamline your workflow with digital systems."
      />
      <PageHeader
        header="Aircraft Maintenance. <br /><span>Revolutionised.</span>"
        subHeader="Transform your maintenance with <br />a truly paperless workflow."
        linkText="Schedule a demo"
        bigHeader
        bigImage={mainImage.childImageSharp.fluid}
        imageWithShadow
        link="/contact?demo&taskcards"
        whiteLink
        tag="Software for your MRO"
        tagStrong="TASK CARDS"
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={10}>
            <Row>
              <Col>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <h3 className={styles.title}>We will help you achieve</h3>
                </ScrollAnimation>
              </Col>
            </Row>
            <Row className={styles.topCardsParent}>
              <Col sm={4}>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <div className={styles.topCardWrapper}>
                    <img src={Simplified} alt="simpified" />
                    <span>Streamlined paperwork compliance</span>
                  </div>
                </ScrollAnimation>
              </Col>
              <Col sm={4}>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <div className={styles.topCardWrapper}>
                    <img src={Manuals} alt="manuals" />
                    <span>
                      Instant access to accurate manuals and reference material
                    </span>
                  </div>
                </ScrollAnimation>
              </Col>
              <Col sm={4}>
                <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                  <div className={styles.topCardWrapper}>
                    <img src={SignoffMin} alt="signoff" />
                    <span>Real-time task information including sign offs</span>
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={12}>
            <Row>
              <Col lg={7} md={6} className={pageStyles.coliPad}>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <Img
                    fluid={digitalSolutionsImg.childImageSharp.fluid}
                    className={styles.screenImgTC}
                  />
                </ScrollAnimation>
              </Col>
              <Col
                lg={4}
                md={5}
                className={`${styles.screenText} ${pageStyles.coliPad}`}
              >
                <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                  <h3 className={`${styles.title} ${styles.titleLeft}`}>
                    Digital Solution. Simplified Processes
                  </h3>
                  <p className={styles.description}>
                    Drop paper task tracking and move to our efficient and
                    simple process.
                  </p>
                  <p className={styles.description}>
                    The TrustFlight Task Cards application streamlines your MRO
                    operation, providing your engineers with fast and accurate
                    access to the procedures they need, makes planning simpler,
                    and smooths inspector approvals leading to safer and more
                    efficient task completion.
                  </p>
                  <p className={styles.description}>
                    Management and delivery of aircraft maintenance has been
                    brought into the 21st century.
                  </p>
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={6} md={8}>
            <Row>
              <Col>
                <ScrollAnimation animateOnce animateIn="fadeInDown" offset={0}>
                  <h3 className={styles.title}>
                    Bring efficiency to your operations
                  </h3>
                </ScrollAnimation>
              </Col>
            </Row>
            <CenteredDiv>
              <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                <h5 className={styles.subTitle}>
                  Speed up maintenance tasks and deliver a smoother and safer
                  experience for your engineers while tracking metrics to help
                  you refine your processes.
                </h5>
              </ScrollAnimation>
            </CenteredDiv>
          </Col>
          <Col lg={10} md={12}>
            <Row className={styles.cardsHolder}>{cards}</Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col md={12}>
              <Row className={styles.titleRow}>
                <Col md={12}>
                  <CenteredDiv>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInDown"
                      offset={0}
                    >
                      <h3 className={styles.title}>
                        Designed with airworthiness and
                        <br /> maintenance experts
                      </h3>
                    </ScrollAnimation>
                  </CenteredDiv>
                </Col>
                <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }}>
                  <CenteredDiv>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInUp"
                      offset={0}
                    >
                      <h4 className={styles.subTitle}>
                        TrustFlight is uniquely embedded in the aviation
                        industry with a team that extends far beyond software
                        engineering to include pilots and aircraft engineers.
                      </h4>
                    </ScrollAnimation>
                  </CenteredDiv>
                </Col>
              </Row>
              <Row className={styles.benefitsRow}>
                <Col
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className={`${styles.benefitsText} ${pageStyles.coliPad}`}
                >
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    offset={0}
                  >
                    <h5
                      className={`${styles.subTitle} ${styles.subTitleSmall}`}
                    >
                      Our Task Cards take a traditionally complex and
                      time-consuming process and simplify it leading to
                      increased quality and productivity. By leveraging a fully
                      digital process, many additional features can be gained.
                    </h5>
                    <div className={styles.tickParent}>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Task allocation and planning</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Automated time tracking</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Seamless inspector sign off</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Accurate automated documentation handling</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Interactive step visualisation</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Back-office analytics and data visualisation
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Intuitive design requires minimal training and reduces
                          errors and omissions
                        </span>
                      </div>
                    </div>
                  </ScrollAnimation>
                </Col>
                <Col lg={7} md={6} className={pageStyles.coliPad}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInRight"
                    offset={0}
                  >
                    <Img
                      fluid={benefitsImg.childImageSharp.fluid}
                      className={styles.screenImgTC}
                    />
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </GradientSection>
      <Container>
      <Row
          className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
        >
          <Col lg={10} md={12}>
            <Row className={pageStyles.markdownCard}>
              <Col md={6} className={pageStyles.markdownIcon}>
                <ScrollAnimation
                  animateOnce
                  animateIn="fadeIn"
                  offset={0}
                  delay={150}
                >
                  <img src={EngineerCAMO} alt="icon" />
                </ScrollAnimation>
              </Col>
              <Col md={6}>
                <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                  <Title>CAMO Benefits</Title>
                  <Text>
                    Our Task Cards application ensures that your engineers can
                    focus their time on what they do best and through real-time
                    updates on task completion, your aircraft’s CAMO will always
                    be in the loop. With a dashboard showing live progress on
                    maintenance, real-time reporting of defects encountered or
                    additional tasks being performed, and fully automatic entry
                    of completed records, you will have unparalleled oversight
                    of your maintenance and associated costs.
                  </Text>
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Seamless
              title="Full Ecosystem.<br /> Seamless Integration."
              text="We’ve worked hard to integrate Task Cards into most popular service provider’s products to ensure that data need only be entered once."
              cards={seamlessCards}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={8} md={10}>
            <Row>
              <Col>
                <ScrollAnimation animateOnce animateIn="fadeInDown" offset={0}>
                  <h3 className={styles.title}>How it works?</h3>
                </ScrollAnimation>
              </Col>
            </Row>
            <CenteredDiv>
              <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                <h5 className={styles.subTitle}>
                  TrustFlight Task Cards works seamlessly with MRO software
                  solutions such as AMOS. <br /><br />
                  By using your MRO software as usual for organising maintenance
                  work orders, TrustFlight will import your workpackages and sync
                  them with the Task Cards app. As your engineers sign-off task
                  steps, the app syncs with the TrustFlight core and, upon
                  completion of the workpackage, will push the status back to
                  your MRO platform for an end-to-end paperless workflow.
                </h5>
              </ScrollAnimation>
            </CenteredDiv>
          </Col>
          <Col lg={8} md={10} xs={10} className={styles.colFullImg}>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
              <img src={DigitalTC} alt="DigitalTaskCards" />
            </ScrollAnimation>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        />
        <Started />
      </Container>
      <AlsoInterestedIn page="task" />
    </Layout>
  );
};

export const query = graphql`
  query TaskCardsQuery {
    mainImage: file(relativePath: { eq: "TaskCards/task-cards-hero@1x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalSolutionsImg: file(
      relativePath: {
        eq: "TaskCards/Task-Cards-Dashboard-Hidden-Filters@2x.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    benefitsImg: file(
      relativePath: {
        eq: "TaskCards/Task-Cards-Dashboard-Hidden-Filters-Condensed-Complete-Task@2x.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
